import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
    Backdrop,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Fade,
    Modal,
    Stack,
    TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Loading from "components/loading";
import TimeAgo from "javascript-time-ago";
import { createGroup, getGroups } from "lib/firebase";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import en from "javascript-time-ago/locale/en.json";
import { useAtomValue } from "jotai";
import { userAtom } from "jotai-state/atoms";

TimeAgo.addDefaultLocale(en);

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function ContactGroups() {
    const [open, setOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [groups, setGroups] = useState([]);

    const userData = useAtomValue(userAtom);

    useEffect(() => {
        setShowLoading(false);

        _getGroups();
    }, []);

    const _getGroups = async () => {
        setGroups(await getGroups(userData.token));
    };

    return (
        <>
            <CreateGroupModal
                setOpen={setOpen}
                open={open}
                token={userData.token}
            />

            {showLoading ? (
                <Loading />
            ) : (
                <Grid container spacing={1}>
                    <Grid padding={1}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardActionArea onClick={() => setOpen(true)}>
                                <CardHeader title="Create new group" />
                                <CardContent>
                                    <center>
                                        <PlusOutlined color="#029DD0" />
                                    </center>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {groups.map((group) => (
                        <Link
                            key={group.groupID}
                            style={{ textDecoration: "none" }}
                            to={`/contacts/${group.groupID}`}
                        >
                            <Grid padding={1}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardActionArea>
                                        <CardHeader title={group.groupName} />

                                        <CardContent>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                            >
                                                <UserOutlined />{" "}
                                                {group.totalContacts} contacts
                                            </Typography>
                                            <br />
                                            <br />
                                            <Typography
                                                sx={{ mb: 1.5 }}
                                                color="text.secondary"
                                            >
                                                Added{" "}
                                                <ReactTimeAgo
                                                    date={group.dateCreated.toDate()}
                                                />
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Link>
                    ))}
                </Grid>
            )}
        </>
    );
}

function CreateGroupModal({ setOpen, open, token }) {
    const handleOpen = () => setOpen(true);
    const [canProceed, setCanProceed] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [groupName, setGroupName] = useState();
    const [amountInSMS, setAmountInSMS] = useState();

    const navigate = useNavigate();

    const handleClose = () => setOpen(showLoading);

    const proceed = async () => {
        setShowLoading(true);
        setCanProceed(false);

        const id = await createGroup({
            groupName,
            token,
        });

        navigate(`/contacts/${id}`);

        setShowLoading(false);
    };

    return (
        <div>
            {/* <SweetAlert
                type="info"
                showCancelButton
                confirmButtonText="Proceed"
                show={showTopUpConfirm}
                title="Confirm"
                text={`Do you want to proceed with the purchase of ${amountInSMS} SMS credits (₵${groupName})?`}
                onConfirm={topUPBalance}
            /> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                keepMounted
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Create new group of contacts
                        </Typography>
                        {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Please specify total amount in cedis or sms
                        </Typography> */}
                        <br />
                        <Stack spacing={1}>
                            <TextField
                                variant="outlined"
                                id="groupName"
                                value={groupName}
                                name="groupName"
                                onChange={(event) => {
                                    setGroupName(event.target.value);
                                    if (event.target.value) {
                                        setCanProceed(true);
                                    } else {
                                        setCanProceed(false);
                                    }
                                }}
                                placeholder="Group name"
                                fullWidth
                                // error={Boolean(touched.email && errors.email)}
                            />
                            {canProceed && (
                                <Button onClick={proceed} variant="outlined">
                                    Proceed
                                </Button>
                            )}
                            {showLoading && <Loading />}
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
