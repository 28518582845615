import React from 'react';

const Loading = () => {
    return (
        <div className="text-container-bg">
            <div className="text-container">
                <span>d</span>
                <span>a</span>
                <span>w</span>
                <span>u</span>
                <span>r</span>
                <span>o</span>
                <span>b</span>
                <span>o</span>
            </div>
        </div>
    );
};

export default Loading;
