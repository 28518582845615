import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

// material-ui
import {
    Box,
    Button,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

// third-party

// project import
import Dot from "components/@extended/Dot";
import { useAtomValue } from "jotai";
import { userAtom } from "jotai-state/atoms";
import { getTemplates } from "lib/firebase";

function createData(templateID, senderID, totalContacts, status, totalAmount) {
    return { templateID, senderID, totalContacts, status, totalAmount };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: "templateID",
        align: "left",
        disablePadding: false,
        label: "Template ID",
    },
    {
        id: "title",
        align: "left",
        disablePadding: true,
        label: "Title",
    },
    {
        id: "totalVariables",
        align: "right",
        disablePadding: false,
        label: "Total Variables",
    },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = "warning";
            title = "Pending";
            break;
        case 1:
            color = "success";
            title = "Approved";
            break;
        case 2:
            color = "error";
            title = "Rejected";
            break;
        default:
            color = "primary";
            title = "None";
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function Templates() {
    const [order] = useState("asc");
    const [orderBy] = useState("templateID");
    const [selected] = useState([]);
    const userData = useAtomValue(userAtom);

    const isSelected = (templateID) => selected.indexOf(templateID) !== -1;
    const location = useLocation();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);

    const countVariables = (string) => {
        var freq = {};
        for (var i = 0; i < string.length; i++) {
            var character = string.charAt(i);
            if (character == "{") {
                if (freq[character]) {
                    freq[character]++;
                } else {
                    freq[character] = 1;
                }
            }
        }

        return freq["{"] ?? 0;
    };

    useEffect(() => {
        const _getTemplates = async () => {
            const templates = await getTemplates(userData.token);

            setRows(templates);
        };
        _getTemplates();
    }, []);

    return (
        <Box>
            {/* <Link  to="/new"> */}
            <Button
                onClick={() => navigate("/templates/new")}
                color="success"
                variant="outlined"
            >
                New template
            </Button>
            {/* </Link> */}
            <TableContainer
                sx={{
                    width: "100%",
                    overflowX: "auto",
                    position: "relative",
                    display: "block",
                    maxWidth: "100%",
                    "& td, & th": { whiteSpace: "nowrap" },
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        "& .MuiTableCell-root:first-child": {
                            pl: 2,
                        },
                        "& .MuiTableCell-root:last-child": {
                            pr: 3,
                        },
                    }}
                >
                    <OrderTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map(
                            (row, index) => {
                                const isItemSelected = isSelected(
                                    row.templateID
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.templateID}
                                        selected={isItemSelected}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            align="left"
                                        >
                                            <Link
                                                color="secondary"
                                                component={RouterLink}
                                                to=""
                                            >
                                                {row.templateID}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.title}
                                        </TableCell>
                                        <TableCell align="right">
                                            {countVariables(row.template)}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
