import { initializeApp } from "firebase/app";
import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
} from "firebase/auth";
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";

import { v4 as uuid } from "uuid";

import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD9i0ENVXrYhqV30wrHP0k4Ai91ZdUbeac",

    authDomain: "dawurobo-users.firebaseapp.com",

    projectId: "dawurobo-users",

    storageBucket: "dawurobo-users.appspot.com",

    messagingSenderId: "254698464983",

    appId: "1:254698464983:web:a23bea506ab57b8ef3b292",

    measurementId: "G-6QKSSFBT4Q",
};

const smsConfig = {
    apiKey: "AIzaSyBcOppcwoVV4TM5D_OdsW53gpp_S5S9U5o",

    authDomain: "dawurobo-sms.firebaseapp.com",

    projectId: "dawurobo-sms",

    storageBucket: "dawurobo-sms.appspot.com",

    messagingSenderId: "993864666164",

    appId: "1:993864666164:web:274fc79f090b9ab5324e61",

    measurementId: "G-LVG70RMGNC",
};

const app = initializeApp(firebaseConfig);

const smsApp = initializeApp(smsConfig, "smsApp");

export const smsDatabase = getFirestore(smsApp);

export const authentication = getAuth(app);

export const database = getFirestore(app);

const analytics = getAnalytics(app);

export const authenticate = async (values, isSignUp) => {
    const res = {};

    try {
        res["user"] = isSignUp
            ? await createUserWithEmailAndPassword(
                  authenticate,
                  values.email,
                  values.password
              )
            : await signInWithEmailAndPassword(
                  authentication,
                  values.email,
                  values.password
              );

        const userData = await getUserData(values.email);

        res["name"] = userData.name;
        res["email"] = userData.email;
        res["accessToken"] = userData.access_token;
    } catch (error) {
        res["error"] = error.message
            .replace("Firebase:", "")
            .replace(/[-]/g, " ")
            .replace("auth/", "");
    }

    return res;
};

export const getUserData = async (email) => {
    const docRef = await getDoc(doc(database, `/user data/${email}`));

    return docRef.data();
};

export const getBalance = async (token) => {
    const docRef = await getDoc(doc(smsDatabase, `/balances/${token}`));

    return docRef.data();
};

export const getGroups = async (token) => {
    const docRef = await getDocs(
        query(
            collection(smsDatabase, `/contact groups/`),
            where("token", "==", token)
        )
    );

    const groups = [];

    docRef.docs.forEach((doc) => {
        groups.push(doc.data());
    });

    return groups;
};

export const getSentSMS = async (token) => {
    const docRef = await getDocs(
        query(
            collection(smsDatabase, `/pending sms v2/`),
            where("token", "==", token),
            orderBy("dateCreated", "desc")
        )
    );

    const sms = [];

    docRef.docs.forEach((doc) => {
        sms.push(doc.data());
    });

    return sms;
};

export const getSentSMSWk = async (token) => {
    const today = new Date();
    const wkAgo = new Date();

    wkAgo.setDate(wkAgo.getDate() - 7);

    const docRef = await getDocs(
        query(
            collection(smsDatabase, `/pending sms v2/`),
            where("token", "==", token),
            orderBy("dateCreated", "desc"),
            limit(100)
            // where("dateCreated", ">=", wkAgo),
            // where("dateCreated", "<=", today)
        )
    );

    const smsSuccess = new Array(7).fill(0);
    const smsFailed = new Array(7).fill(0);
    const smsPending = new Array(7).fill(0);

    const dayMap = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6,
    };

    docRef.docs.forEach((doc) => {
        const data = doc.data();

        const dayIndex = dayMap[data.day];

        switch (data.status) {
            case "Sent":
                smsSuccess[dayIndex]++;
                break;
            case "Failed":
                smsFailed[dayIndex]++;
                break;
            default:
                smsPending[dayIndex]++;
        }
    });

    return [
        {
            name: "Sent",
            data: smsSuccess,
        },
        {
            name: "Failed",
            data: smsFailed,
        },
        {
            name: "Pending",
            data: smsPending,
        },
    ];
};

export const saveSenderID = async (id, token) => {
    const ids = (await getSenderIDs(token)).map((id) => id.id);

    if (!ids.includes(id)) {
        await addDoc(collection(smsDatabase, `/sender ids`), {
            id,
            token,
            status: "awaiting approval",
            date: serverTimestamp(),
        });
    }
};

export const getTransactionHistory = async (token) => {
    const data = await getDoc(doc(smsDatabase, `balances/${token}`));

    return data.data();
};

export const getSenderIDs = async (token) => {
    const docs = await getDocs(
        query(
            collection(smsDatabase, `/sender ids`),
            where("token", "==", token)
        )
    );

    const ids = [];

    docs.docs.forEach((doc) => ids.push(doc.data()));

    return ids;
};

export const saveSMS = async (data) => {
    data.dateCreated = serverTimestamp();
    data.messageID = uuid();

    await setDoc(doc(smsDatabase, `/pending sms v2/${data.messageID}`), data);

    updateDoc(doc(smsDatabase, `/meta data/${data.token}`), {
        totalSMS: increment(1),
        totalBalance: increment(-data.totalAmount),
    });
};

export const getMetaData = async (token) => {
    const docRef = await getDoc(doc(smsDatabase, `/meta data/${token}`));

    if (docRef.exists()) {
        return docRef.data();
    } else {
        const data = {
            totalBalance: 10,
            totalContacts: 0,
            totalGroups: 0,
            totalSMS: 0,
            totalTemplates: 0,
        };
        setDoc(doc(smsDatabase, `/meta data/${token}`), data);
        return data;
    }
};

export const createGroup = async (data) => {
    data.dateCreated = serverTimestamp();
    // data.totalContacts = 0;
    data.groupID = uuid();
    await setDoc(doc(smsDatabase, `/contact groups/${data.groupID}`), data);

    updateDoc(doc(smsDatabase, `/meta data/${data.token}`), {
        totalGroups: increment(1),
    });

    return data.groupID;
};

export const updateContacts = async (data, override) => {
    await updateDoc(doc(smsDatabase, `/contact groups/${data.groupID}`), {
        contacts: override ? data.contacts : arrayUnion(...data.contacts),
    });

    updateDoc(doc(smsDatabase, `/meta data/${data.token}`), {
        totalContacts: override
            ? data.contacts.length
            : increment(data.contacts.length),
    });
    // updateDoc(doc(smsDatabase, `/contact groups/${data.groupID}`), { totalContacts: increment(rowLength) });

    return data.groupID;
};
export const updateGroupName = async (data) => {
    await updateDoc(doc(smsDatabase, `/contact groups/${data.groupID}`), {
        groupName: data.groupName,
    });
};

export const deleteContactGroup = async (data) => {
    await deleteDoc(doc(smsDatabase, `/contact groups/${data.groupID}`));
    updateDoc(doc(smsDatabase, `/meta data/${data.token}`), {
        totalGroups: increment(-1),
        totalContacts: increment(-data.contactsLength),
    });
};

export const saveTemplate = async (data) => {
    data.dateCreated = serverTimestamp();
    data.templateID = uuid();
    await addDoc(collection(smsDatabase, "/templates"), data);
    updateDoc(doc(smsDatabase, `/meta data/${data.createdBy}`), {
        totalTemplates: increment(1),
    });
};

export const getTemplates = async (token) => {
    const docRef = await getDocs(
        query(
            collection(smsDatabase, `/templates/`),
            where("createdBy", "==", token)
        )
    );

    const templates = [];

    docRef.docs.forEach((doc) => {
        templates.push(doc.data());
    });

    return templates;
};

export const signOut = async () => await authentication.signOut();

export const saveAnalytics = async (analyticsData) =>
    logEvent(analytics, "send_sms", analyticsData);

export {
    arrayRemove,
    collection,
    doc,
    getDoc,
    onSnapshot,
    query,
    updateDoc,
    where,
};
