import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
    formatContacts,
    getContactErrs,
    networks,
} from "utils/get-contact-errs";

// material-ui
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    Fade,
    FormControlLabel,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TextareaAutosize,
    Typography,
} from "@mui/material";

// third-party

// project import
import Dot from "components/@extended/Dot";

import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";

import Loading from "components/loading";
import { useAtomValue } from "jotai";
import { userAtom } from "jotai-state/atoms";
import {
    deleteContactGroup,
    doc,
    onSnapshot,
    smsDatabase,
    updateContacts,
    updateGroupName,
} from "lib/firebase";
import { useParams } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const networkColors = {
    MTN: "warning",
    AirtelTigo: "primary",
    Vodafone: "error",
    GLO: "success",
};

// const non_international_prefixes = ['024', '055', '057', '056', '020', '050', '023', '054', '026', '027', '025', '059'];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: "number",
        align: "left",
        disablePadding: false,
        label: "Number",
    },
    // {
    //     id: 'name',
    //     align: 'left',
    //     disablePadding: true,
    //     label: 'Name'
    // },
    {
        id: "network",
        align: "left",
        disablePadding: false,
        label: "Network",
    },
    // {
    //     id: 'group',
    //     align: 'left',
    //     disablePadding: false,

    //     label: 'group'
    // },
    // {
    //     id: 'action',
    //     align: 'right',
    //     disablePadding: false,
    //     label: 'Action'
    // }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - group ||============================== //

const Ordergroup = ({ group }) => {
    let color;
    let title;

    switch (group) {
        case 0:
            color = "warning";
            break;
        case 1:
            color = "success";
            break;
        case 2:
            color = "error";
            break;
        default:
            color = "primary";
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    );
};

Ordergroup.propTypes = {
    group: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function Contacts() {
    const userData = useAtomValue(userAtom);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDeleteGroupConfirm, setShowDeleteGroupConfirm] = useState(false);

    const [showSaving, setShowSaving] = useState(false);

    const [rows, setRows] = useState([]);
    const [groupName, setGroupName] = useState("");

    const [order] = useState("asc");
    const [orderBy] = useState("number");
    const [selected] = useState([]);

    const { groupID } = useParams();
    const [open, setOpen] = useState(false);
    const [editContactOpen, setEditContactOpen] = useState(false);

    const [activeContact, setActiveContact] = useState();

    const isSelected = (number) => selected.indexOf(number) !== -1;

    const navigate = useNavigate();

    useEffect(() => {
        onSnapshot(doc(smsDatabase, `/contact groups/${groupID}`), (doc) => {
            if (doc.data() && doc.data().contacts) {
                setRows(doc.data().contacts);
                setGroupName(doc.data().groupName);
            }
        });
    }, []);

    const handleContactClick = (contact) => {
        setActiveContact(contact);
        setEditContactOpen(true);
    };

    return (
        <>
            <AddContactsModal
                rows={rows}
                setOpen={setOpen}
                open={open}
                token={userData.token}
                groupID={groupID}
            />
            <EditContactModal
                activeContact={activeContact}
                rows={rows}
                setOpen={setEditContactOpen}
                open={editContactOpen}
                token={userData.token}
                groupID={groupID}
                setShowDeleteConfirm={setShowDeleteConfirm}
            />

            <SweetAlert
                key={"group-delete"}
                type="warning"
                showCancelButton={true}
                onCancel={() => setShowDeleteGroupConfirm(false)}
                confirmButtonText="Delete"
                show={showDeleteGroupConfirm}
                title="Confirm"
                text={`Do you want delete ${groupName}?`}
                onConfirm={async () => {
                    setShowDeleteGroupConfirm(false);

                    await deleteContactGroup({
                        groupID,
                        token: userData.token,
                        contactsLength: rows.length,
                    });

                    setTimeout(() => {
                        navigate("/contacts");
                    }, 1000);

                    // await updateContacts(
                    //     {
                    //         contacts: rows.filter((x) => x !== activeContact),
                    //         token: userData.token,
                    //         groupID
                    //     },
                    //     true
                    // );
                }}
            />

            <SweetAlert
                key={"contact-delete"}
                type="warning"
                showCancelButton={true}
                onCancel={() => setShowDeleteConfirm(false)}
                confirmButtonText="Delete"
                show={showDeleteConfirm}
                title="Confirm"
                text={`Do you want delete ${activeContact} from this ${groupName}?`}
                onConfirm={async () => {
                    setShowDeleteConfirm(false);

                    await updateContacts(
                        {
                            contacts: rows.filter((x) => x !== activeContact),
                            token: userData.token,
                            groupID,
                        },
                        true
                    );
                }}
            />

            <Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                        variant="outlined"
                        id="contacts"
                        value={groupName}
                        name="contacts"
                        onChange={async (event) => {
                            setShowSaving(true);
                            const value = event.target.value;

                            await updateGroupName({
                                groupID,
                                groupName: value,
                            });
                            setShowSaving(false);
                        }}
                        placeholder="Contacts here..."
                        fullWidth
                        // error={Boolean(touched.email && errors.email)}
                    />

                    <Button
                        onClick={() => setShowDeleteGroupConfirm(true)}
                        color="error"
                        variant="outlined"
                    >
                        Delete Group
                    </Button>
                </Stack>

                {showSaving && (
                    <Typography color="secondary">Saving...</Typography>
                )}

                <TableContainer
                    sx={{
                        width: "100%",
                        overflowX: "auto",
                        position: "relative",
                        display: "block",
                        maxWidth: "100%",
                        "& td, & th": { whiteSpace: "nowrap" },
                    }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        sx={{
                            "& .MuiTableCell-root:first-child": {
                                pl: 2,
                            },
                            "& .MuiTableCell-root:last-child": {
                                pr: 3,
                            },
                        }}
                    >
                        <OrderTableHead order={order} orderBy={orderBy} />
                        <TableBody>
                            {stableSort(
                                rows,
                                getComparator(order, orderBy)
                            ).map((row, index) => {
                                const isItemSelected = isSelected(row.number);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        onClick={() => handleContactClick(row)}
                                        hover
                                        role="checkbox"
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.number}
                                        selected={isItemSelected}
                                    >
                                        {row && (
                                            <>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    align="left"
                                                >
                                                    {/* <Link color="secondary" component={RouterLink} to=""> */}
                                                    {row}
                                                </TableCell>
                                                {/* <TableCell align="left">{row.name}</TableCell> */}
                                                <TableCell align="right">
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                        alignItems="center"
                                                    >
                                                        <Dot
                                                            color={
                                                                networkColors[
                                                                    networks[
                                                                        row.slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                    ]
                                                                ]
                                                            }
                                                        />

                                                        <Typography>
                                                            {
                                                                networks[
                                                                    row.slice(
                                                                        0,
                                                                        3
                                                                    )
                                                                ]
                                                            }
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                {/* <Ordergroup group={row.group} /> */}
                                                {/* <TableCell align="left">
                                    </TableCell> */}
                                                {/* <TableCell align="right">
                                                    <Button />
                                                </TableCell> */}
                                            </>
                                        )}
                                    </TableRow>
                                );
                            })}

                            <TableRow
                                onClick={() => setOpen(true)}
                                hover
                                role="checkbox"
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                                tabIndex={-1}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                >
                                    <Typography color="primary">
                                        New Contact <PlusOutlined />
                                    </Typography>
                                </TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}

function AddContactsModal({ rows, setOpen, open, token, groupID }) {
    const handleOpen = () => setOpen(true);
    const [canProceed, setCanProceed] = useState(false);
    const [stillProceed, setStillProceed] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [contacts, setContacts] = useState();
    const [inCorrectNumbers, setInCorrectNumbers] = useState([]);

    const handleClose = () => setOpen(showLoading);

    const handleChange = (event) => {
        if (inCorrectNumbers.length !== 0) {
            setInCorrectNumbers([]);
        }
        const value = formatContacts(event);

        const contactData = getContactErrs(value, rows);

        setContacts(contactData.contactsListFinal.toString());

        setInCorrectNumbers(contactData.errs);
    };

    const proceed = async () => {
        // setCanProceed(false);
        let formattedContacts = contacts;

        let formattedContactsListFinal = [];

        let errs = [];

        if (!stillProceed) {
            if (formattedContacts.endsWith(",")) {
                formattedContacts = formattedContacts.slice(
                    0,
                    formattedContacts.length - 1
                );

                setContacts(formattedContacts);
            }

            const contactData = getContactErrs(formattedContacts, rows);

            errs = contactData.errs;
            formattedContactsListFinal = contactData.contactsListFinal;

            setInCorrectNumbers(errs);
        }

        if (canProceed || errs.length === 0) {
            setShowLoading(true);

            await updateContacts(
                {
                    contacts: formattedContactsListFinal,
                    token,
                    groupID,
                },
                false
            );
            setShowLoading(false);
            setOpen(false);

            // alert(token)
        }
        // const id = await createGroup({
        //     contacts,
        //     token
        // });

        // navigate(`/contacts/${id}`);

        // setShowLoading(false);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                keepMounted
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Add contacts
                        </Typography>
                        {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Please specify total amount in cedis or sms
                        </Typography> */}
                        <br />
                        <Stack spacing={1}>
                            <TextareaAutosize
                                minRows={20}
                                disabled={showLoading}
                                variant="outlined"
                                id="contacts"
                                value={contacts}
                                name="contacts"
                                onChange={handleChange}
                                placeholder="Contacts here..."
                                fullWidth
                                // error={Boolean(touched.email && errors.email)}
                            />
                            <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                                color="red"
                            >
                                {inCorrectNumbers.length > 0 && (
                                    <>
                                        * Incorrect numbers
                                        <br />
                                        {inCorrectNumbers.map((el) => (
                                            <>
                                                {/* {el.number} - {el.reason} */}
                                                <Stack
                                                    direction="row"
                                                    spacing={5}
                                                >
                                                    <Typography>
                                                        {el.number} -{" "}
                                                        {el.reason}
                                                    </Typography>
                                                    <DeleteOutlined
                                                        onClick={() => {
                                                            setContacts(
                                                                contacts
                                                                    .replace(
                                                                        `${el.number},`,
                                                                        ""
                                                                    )
                                                                    .replace(
                                                                        `,${el.number}`,
                                                                        ""
                                                                    )
                                                                    .replace(
                                                                        `${el.number}`,
                                                                        ""
                                                                    )
                                                            );

                                                            setInCorrectNumbers(
                                                                inCorrectNumbers.filter(
                                                                    (x) =>
                                                                        x.errID !==
                                                                        el.errID
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Stack>
                                                <br />
                                            </>
                                        ))}
                                    </>
                                )}
                            </Typography>

                            {inCorrectNumbers.length !== 0 && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                setStillProceed(
                                                    e.target.checked
                                                );
                                                setCanProceed(e.target.checked);
                                            }}
                                            color="error"
                                        />
                                    }
                                    label="Treat as international numbers"
                                />
                            )}
                            {(contacts && inCorrectNumbers.length == 0) ||
                            (stillProceed && !showLoading) ? (
                                <Button
                                    disabled={showLoading}
                                    onClick={proceed}
                                    variant="outlined"
                                >
                                    Proceed
                                </Button>
                            ) : (
                                <></>
                            )}
                            {showLoading && <Loading />}
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

function EditContactModal({
    setShowDeleteConfirm,
    rows,
    activeContact,
    setOpen,
    open,
    token,
    groupID,
}) {
    const handleOpen = () => setOpen(true);
    const [canProceed, setCanProceed] = useState(false);
    const [stillProceed, setStillProceed] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [contacts, setContacts] = useState();
    const [inCorrectNumbers, setInCorrectNumbers] = useState([]);

    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(showLoading);
        setContacts();
        setInCorrectNumbers([]);
        setCanProceed(false);
        setStillProceed(false);
    };

    const formatContacts = (event) => {
        if (inCorrectNumbers.length !== 0) {
            setInCorrectNumbers([]);
        }

        let value = event.target.value;

        value = value.replace("\r\n", " ");
        value = value.replace("\n\r", " ");
        value = value.replace("\r", " ");
        value = value.replace(/[" "]/g, ",");
        value = value.replace(", ", ",");
        value = value.replace(/[\n\r]/g, ",");

        setContacts(value);
    };

    const proceed = async () => {
        // setCanProceed(false);
        let formattedContacts = contacts;

        const formattedContactsListFinal = [];

        let errs = [];

        if (!stillProceed) {
            if (formattedContacts.endsWith(",")) {
                formattedContacts = formattedContacts.slice(
                    0,
                    formattedContacts.length - 1
                );

                setContacts(formattedContacts);
            }

            const contactData = getContactErrs(formattedContacts, rows);

            errs = contactData.errs;

            setInCorrectNumbers(errs);
        }

        if (canProceed || errs.length === 0) {
            setShowLoading(true);

            const activeIndex = rows.indexOf(activeContact);

            rows[activeIndex] = contacts;

            await updateContacts(
                {
                    contacts: rows,
                    token,
                    groupID,
                },
                true
            );

            setShowLoading(false);
            setOpen(false);
            handleClose();

            // alert(token)
        }
        // const id = await createGroup({
        //     contacts,
        //     token
        // });

        // navigate(`/contacts/${id}`);

        // setShowLoading(false);
    };

    return (
        <div>
            {/* <SweetAlert
                type="info"
                showCancelButton
                confirmButtonText="Proceed"
                show={showTopUpConfirm}
                title="Confirm"
                text={`Do you want to proceed with the purchase of ${amountInSMS} SMS credits (₵${contacts})?`}
                onConfirm={topUPBalance}
            /> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                keepMounted={false}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Edit {activeContact}
                        </Typography>
                        {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Please specify total amount in cedis or sms
                        </Typography> */}
                        <br />
                        <Stack spacing={1}>
                            <TextField
                                disabled={showLoading}
                                variant="outlined"
                                id="contacts"
                                value={contacts ?? activeContact}
                                name="contacts"
                                defaultValue={activeContact}
                                onChange={formatContacts}
                                placeholder="Contacts here..."
                                fullWidth
                                // error={Boolean(touched.email && errors.email)}
                            />
                            <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                                color="red"
                            >
                                {inCorrectNumbers.length > 0 && (
                                    <>
                                        * Incorrect numbers
                                        <br />
                                        {inCorrectNumbers.map((el) => (
                                            <>
                                                {el.number} - {el.reason}
                                                <br />
                                            </>
                                        ))}
                                    </>
                                )}
                            </Typography>

                            {inCorrectNumbers.length !== 0 && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                setStillProceed(
                                                    e.target.checked
                                                );
                                                setCanProceed(e.target.checked);
                                            }}
                                            color="error"
                                        />
                                    }
                                    label="Treat as international numbers"
                                />
                            )}
                            {(contacts &&
                                inCorrectNumbers.length == 0 &&
                                contacts != activeContact) ||
                            (stillProceed && !showLoading) ? (
                                <Button
                                    color="success"
                                    disabled={showLoading}
                                    onClick={proceed}
                                    variant="outlined"
                                >
                                    Update
                                </Button>
                            ) : (
                                <></>
                            )}
                            <Button
                                disabled={showLoading}
                                onClick={() => {
                                    setShowDeleteConfirm(true);
                                    handleClose();
                                }}
                                color="error"
                                variant="outlined"
                            >
                                Delete
                            </Button>
                            {showLoading && <Loading />}
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
