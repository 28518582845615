// material-ui
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextareaAutosize,
    Typography,
} from "@mui/material";

// project import
import AnimateButton from "../../components/@extended/AnimateButton";
import MainCard from "../../components/MainCard";

import Loading from "components/loading";
import { Formik } from "formik";
import { useAtomValue } from "jotai";
import {
    createGroup,
    getGroups,
    getMetaData,
    getSenderIDs,
    saveSMS,
    saveTemplate,
    updateContacts,
} from "lib/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { userAtom } from "jotai-state/atoms";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import { formatContacts, getContactErrs } from "utils/get-contact-errs";
// ================================|| LOGIN ||================================ //

const NewMessage = () => {
    const [templates, setTemplates] = useState([]);
    const [senderIDs, setSenderIDs] = useState();

    const [contacts, setContacts] = useState([]);
    const [balance, setBalance] = useState();
    const [saveMessage, setSaveMessage] = useState(false);
    const [saveContacts, setSaveContacts] = useState(false);
    const userData = useAtomValue(userAtom);
    const navigate = useNavigate();
    const [showInsufficientBalance, setShowInsufficientBalance] =
        useState(false);
    const [showSuccessMsg, setShowshowSuccessMsg] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const _getSenderIDs = async () => {
        const ids = await getSenderIDs(userData.token);

        setSenderIDs(ids);
    };

    const _getTemplates = async () => {
        // const templates = await getTemplates(userData.token);
        const contacts = await getGroups(userData.token);

        const balance = await getMetaData(userData.token);

        setBalance(balance.totalBalance);

        setTemplates(templates);
        setContacts(contacts);
    };

    useEffect(() => {
        _getTemplates();
        _getSenderIDs();
    }, []);

    const calculateContactsPrice = (value) => {
        let non_international_prefixes = [
            "024",
            "055",
            "057",
            "056",
            "020",
            "050",
            "023",
            "054",
            "026",
            "027",
            "025",
            "059",
        ];

        let international_numbers = 0;

        let non_international_numbers = 0;

        value = value.replace("\r\n", " ");
        value = value.replace("\n\r", " ");
        value = value.replace("\r", " ");
        value = value.replace(/[" "]/g, ",");
        value = value.replace(", ", ",");
        value = value.replace("233", "0");
        value = value.replace(/[\n\r]/g, ",");

        value.split(",").forEach((contact) => {
            contact = contact.replace(/\s/g, "");

            if (contact.length === 9) {
                contact = "0" + contact;
            }

            const index = contact.slice(0, 3);

            if (contact && contact !== "\n" && contact !== "\r") {
                if (
                    non_international_prefixes.includes(index) &&
                    contact.length === 10
                ) {
                    non_international_numbers += 1;
                } else {
                    international_numbers += 1;
                }
            }
        });

        let international_numbers_price = 0;

        let non_international_numbers_price = 0;

        if (international_numbers > 0) {
            international_numbers_price = international_numbers * 5;
        }
        if (non_international_numbers > 0) {
            non_international_numbers_price = non_international_numbers;

            // else {
            //     non_international_numbers_price = (non_international_numbers)

            // }
        }

        let _price =
            international_numbers_price + non_international_numbers_price;

        return _price;
    };

    const calculateMessagePrice = (value) => {
        let price;
        let bf;

        let totalChars = value.length;
        if (totalChars <= 161) {
            price = 1;
        } else if (totalChars >= 161 && totalChars <= 321) {
            bf = price;
            price = 2;
        } else if (totalChars >= 322 && totalChars <= 482) {
            bf = price;
            price = 3;
        } else if (totalChars >= 482 && totalChars <= 683) {
            bf = price;
            price = 4;
        } else if (totalChars >= 644 && totalChars <= 804) {
            bf = price;
            price = 5;
        } else if (totalChars >= 805 && totalChars <= 965) {
            bf = price;
            price = 6;
        } else if (totalChars >= 966 && totalChars <= 1126) {
            bf = price;
            price = 7;
        } else if (totalChars >= 1127 && totalChars <= 1287) {
            bf = price;
            price = 8;
        } else if (totalChars >= 1128 && totalChars <= 1448) {
            bf = price;
            price = 9;
        } else {
            bf = price;
            price = 10;
        }

        return price;
    };

    return !senderIDs ? (
        <Loading />
    ) : (
        <Wrapper>
            <SweetAlert
                key={"group-delete"}
                type="error"
                showCancelButton={true}
                onCancel={() => setShowInsufficientBalance(false)}
                confirmButtonText="Top up"
                show={showInsufficientBalance}
                title="Confirm"
                text={`Opps! You have ${balance} SMS left. You need to top up.`}
                onConfirm={async () => {
                    setShowInsufficientBalance(false);
                }}
            />
            <SweetAlert
                key={"success"}
                type="success"
                showCancelButton={false}
                onCancel={() => setShowshowSuccessMsg(false)}
                confirmButtonText="OK"
                show={showSuccessMsg}
                title="Success!"
                text={`All messages have been sent`}
                onConfirm={async () => {
                    setShowshowSuccessMsg(false);
                }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                    >
                        <Typography variant="h3">Send SMS</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            senderID:
                                senderIDs.length > 0 &&
                                !senderIDs[0].status.includes("awaiting")
                                    ? senderIDs[0].id
                                    : "",
                            message: "",
                            contacts: "",
                            submit: null,
                        }}
                        onSubmit={async (values, { setErrors, setStatus }) => {
                            try {
                                const messagePrice = calculateMessagePrice(
                                    values.message
                                );
                                const contactPrice = calculateContactsPrice(
                                    values.contacts
                                );
                                // alert(messagePrice);
                                // alert(contactPrice);

                                const cost =
                                    (messagePrice * contactPrice * 0.04) / 0.04;

                                const balance = await getMetaData(
                                    userData.token
                                );

                                const insufficientBalance =
                                    cost > balance.totalBalance;

                                if (insufficientBalance) {
                                    setShowInsufficientBalance(
                                        insufficientBalance
                                    );
                                } else {
                                    const destinations = [];

                                    values.contacts
                                        .split(",")
                                        .forEach((contact, index) => {
                                            destinations.push({
                                                destination: contact,
                                                msgid: index,
                                            });
                                        });

                                    setIsSubmitting(true);

                                    fetch(
                                        "https://gateway.api.dawurobo.com/v1/dawurobo_sms/v3/sms/send",
                                        {
                                            method: "POST",
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                            },
                                            body: JSON.stringify({
                                                senderid: values.senderID,
                                                destinations: destinations,
                                                message: values.message,
                                            }),
                                        }
                                    )
                                        .then(async (res) => {
                                            const data = { ...values };
                                            delete data.submit;
                                            data.sent_contacts =
                                                values.contacts.split(",");
                                            data.unsent_contacts = [];
                                            data.token = userData.token;
                                            data.totalAmount = cost;
                                            data.day =
                                                new Date().toLocaleString(
                                                    "en-US",
                                                    {
                                                        weekday: "long",
                                                    }
                                                );
                                            data.status = "Sent";
                                            delete data.contacts;
                                            await saveSMS(data);
                                            setShowshowSuccessMsg(true);
                                            setIsSubmitting(false);
                                        })
                                        .catch((err) => {
                                            setIsSubmitting(false);
                                        });

                                    if (saveContacts) {
                                        const groupID = await createGroup({
                                            groupName:
                                                "Contact Group with " +
                                                values.contacts.split(",")
                                                    .length +
                                                " contacts",
                                            token: userData.token,
                                        });

                                        updateContacts(
                                            {
                                                contacts:
                                                    values.contacts.split(","),
                                                token: userData.token,
                                                groupID,
                                            },
                                            true
                                        );
                                    }

                                    if (saveMessage) {
                                        saveTemplate({
                                            title: `Message Template ${new Date()}`,
                                            template: values.message,
                                            createdBy: userData.token,
                                        });
                                    }
                                }
                            } catch (err) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            setFieldValue,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            {/* <InputLabel htmlFor="senderID">sender ID</InputLabel>
                      <OutlinedInput
                        id="senderID"
                        variant="outlined"
                        type="text"
                        inputProps={{ maxLength: 11 }}
                        value={values.senderID}
                        name="senderID"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="sender ID must be less than 11 characters"
                        fullWidth
                      /> */}
                                            <InputLabel htmlFor="senderID">
                                                Sender ID
                                            </InputLabel>

                                            <Select
                                                labelId="Sender ID"
                                                name="senderID"
                                                value={values.senderID}
                                                id="senderID"
                                                onChange={handleChange}
                                            >
                                                {senderIDs.length === 0 ? (
                                                    <MenuItem
                                                        onClick={() =>
                                                            navigate(
                                                                "/sender-ids"
                                                            )
                                                        }
                                                        value={""}
                                                    >
                                                        Create Sender ID
                                                    </MenuItem>
                                                ) : (
                                                    senderIDs.map((id) => (
                                                        <MenuItem
                                                            disabled={id.status.includes(
                                                                "awaiting"
                                                            )}
                                                            value={id.id}
                                                        >
                                                            {id.id} -{" "}
                                                            {id.status}
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            {/* <Stack direction="row" spacing={5}> */}
                                            <InputLabel htmlFor="message">
                                                Message
                                            </InputLabel>
                                            {/* {templates && (
                        <FormControl>
                          <InputLabel htmlFor="templates">Templates</InputLabel>

                          <Select
                            labelId="templates"
                            label="Age"
                            name="message"
                            onChange={handleChange}
                            // onChange={(e) => values.message = e.target.value}
                          >
                            <MenuItem value={""}>{"None"}</MenuItem>
                            {templates.map((temp) => (
                              <MenuItem value={temp.template}>
                                {temp.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )} */}
                                            {/* <Button
                          onClick={() => navigate("/messages/new")}
                          color="success"
                          variant="outlined"
                        >
                          Use template
                        </Button> */}
                                            {/* </Stack> */}
                                            <TextareaAutosize
                                                fullWidth
                                                minRows={20}
                                                variant="outlined"
                                                error={Boolean(
                                                    touched.message &&
                                                        errors.message
                                                )}
                                                id="message"
                                                value={values.message}
                                                name="message"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="Message"
                                            />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="contacts">
                                                Contacts
                                            </InputLabel>

                                            {contacts && (
                                                <FormControl>
                                                    <InputLabel htmlFor="myContacts">
                                                        My Contacts
                                                    </InputLabel>

                                                    <Select
                                                        labelId="myContacts"
                                                        label="My Contacts"
                                                        name="contacts"
                                                        onChange={handleChange}
                                                        // onChange={(e) => values.message = e.target.value}
                                                    >
                                                        <MenuItem value={""}>
                                                            {"None"}
                                                        </MenuItem>
                                                        {contacts &&
                                                            contacts.map(
                                                                (contact) => (
                                                                    <MenuItem
                                                                        value={
                                                                            contact.contacts
                                                                                ? contact.contacts.toString()
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {
                                                                            contact.groupName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            <TextareaAutosize
                                                fullWidth
                                                minRows={20}
                                                variant="outlined"
                                                error={Boolean(
                                                    touched.contacts &&
                                                        errors.contacts
                                                )}
                                                id="contacts"
                                                value={values.contacts}
                                                name="contacts"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    const value =
                                                        formatContacts(event);

                                                    const contactData =
                                                        getContactErrs(
                                                            value,
                                                            []
                                                        );

                                                    setFieldValue(
                                                        "contacts",
                                                        contactData.contactsListFinal.toString()
                                                    );
                                                }}
                                                placeholder="Contacts"
                                            />
                                            {/* {contacts &&
                                                contacts.filter(
                                                    (x) =>
                                                        x.contacts
                                                            .sort()
                                                            .toString() ===
                                                        values.contacts
                                                            .split(",")
                                                            .sort()
                                                            .toString()
                                                ).length <= 0 &&
                                                values.contacts && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setSaveContacts(
                                                                        e.target
                                                                            .checked
                                                                    );
                                                                    // setCanProceed(e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label="Save contact to a group"
                                                    />
                                                )} */}
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sx={{ mt: -1 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={<Typography variant="h6">Keep me sign in</Typography>}
                                    /> */}
                                        </Stack>
                                    </Grid>
                                    {errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText error>
                                                {errors.submit}
                                            </FormHelperText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        {isSubmitting ? (
                                            <Loading />
                                        ) : (
                                            <AnimateButton>
                                                {balance !== "undefined" &&
                                                    values.senderID.length !==
                                                        0 &&
                                                    values.message.length !==
                                                        0 &&
                                                    values.contacts.length !==
                                                        0 && (
                                                        <Button
                                                            disableElevation
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Send
                                                        </Button>
                                                    )}
                                            </AnimateButton>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider> */}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default NewMessage;

const Wrapper = ({ children }) => {
    return (
        <Box sx={{ minHeight: "100vh" }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: "100vh",
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: {
                                xs: "calc(100vh - 134px)",
                                md: "calc(100vh - 112px)",
                            },
                        }}
                    >
                        <Grid item>
                            <MainCard
                                sx={{
                                    maxWidth: { xs: 400, lg: 475 },
                                    margin: { xs: 2.5, md: 3 },
                                    "& > *": {
                                        flexGrow: 1,
                                        flexBasis: "50%",
                                    },
                                }}
                                content={false}
                                border={false}
                                boxShadow
                                shadow={(theme) => theme.customShadows.z1}
                            >
                                <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
                                    {children}
                                </Box>
                            </MainCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
