// assets
import { MessageOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";

// icons

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "new_message",
      title: "Send Message",
      type: "item",
      url: "/messages/new",
      icon: SendOutlined,
    },
    {
      id: "messages",
      title: "Messages",
      type: "item",
      url: "/messages",
      icon: MessageOutlined,
    },
    {
      id: "sender_ids",
      title: "Sender IDs",
      type: "item",
      url: "/sender-ids",
      icon: UserOutlined,
    },
    // {
    //     id: 'templates',
    //     title: 'Templates',
    //     type: 'item',
    //     url: '/templates',
    //     icon: FormOutlined
    // },
    // {
    //     id: 'util-color',
    //     title: 'Color',
    //     type: 'item',
    //     url: '/color',
    //     icon: icons.BgColorsOutlined
    // },
    // {
    //     id: 'util-shadow',
    //     title: 'Shadow',
    //     type: 'item',
    //     url: '/shadow',
    //     icon: icons.BarcodeOutlined
    // },
    // {
    //     id: 'ant-icons',
    //     title: 'Ant Icons',
    //     type: 'item',
    //     url: '/icons/ant',
    //     icon: icons.AntDesignOutlined,
    //     breadcrumbs: false
    // }
  ],
};

export default utilities;
