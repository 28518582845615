import Loading from "components/loading";
import { useSetAtom } from "jotai";
import { userAtom } from "jotai-state/atoms";
import { jwtDecode } from "jwt-decode";
import { getUserData } from "lib/firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthJWT = () => {
    const setUserData = useSetAtom(userAtom);
    const navigate = useNavigate();

    const handleJWT = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
        try {
            const decoded = jwtDecode(token);
            const { email, aud } = decoded;

            if (aud === "dawurobo-users") {
                const userData = await getUserData(email);

                setUserData({
                    name: userData.name,
                    email: userData.email,
                    token: userData.access_token,
                });

                navigate("/");
            } else {
                navigate("/");
            }
        } catch (error) {
            navigate("/");
        }
    };

    useEffect(() => {
        handleJWT();
    }, []);
    return <Loading />;
};

export default AuthJWT;
