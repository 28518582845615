// material-ui
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    TextareaAutosize,
    Typography,
} from "@mui/material";

// project import
import AnimateButton from "../../components/@extended/AnimateButton";
import MainCard from "../../components/MainCard";

import Loading from "components/loading";
import { Formik } from "formik";
import { useAtomValue } from "jotai";
import { userAtom } from "jotai-state/atoms";
import { saveTemplate } from "lib/firebase";
import { useNavigate } from "react-router";

// ================================|| LOGIN ||================================ //

const NewTemplate = () => {
    const navigate = useNavigate();
    const userData = useAtomValue(userAtom);
    return (
        <Wrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                    >
                        <Typography variant="h3">New Template</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            title: "",
                            template: "",
                            submit: null,
                        }}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                setSubmitting(true);
                                setStatus({ success: false });

                                values.createdBy = userData.token;
                                delete values.submit;
                                await saveTemplate(values);

                                navigate("/templates");
                            } catch (err) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="title">
                                                Title
                                            </InputLabel>
                                            <OutlinedInput
                                                id="title"
                                                variant="outlined"
                                                type="text"
                                                value={values.title}
                                                name="title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="Template title"
                                                fullWidth
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="template">
                                                Template
                                            </InputLabel>
                                            <TextareaAutosize
                                                fullWidth
                                                minRows={20}
                                                variant="outlined"
                                                error={Boolean(
                                                    touched.password &&
                                                        errors.password
                                                )}
                                                id="template"
                                                value={values.template}
                                                name="template"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="Template goes here..."
                                            />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sx={{ mt: -1 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={<Typography variant="h6">Keep me sign in</Typography>}
                                    /> */}
                                        </Stack>
                                    </Grid>
                                    {errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText error>
                                                {errors.submit}
                                            </FormHelperText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <AnimateButton>
                                            {values.title.length !== 0 &&
                                                values.template.length !==
                                                    0 && (
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Save
                                                    </Button>
                                                )}
                                        </AnimateButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <FirebaseSocial /> */}
                                        {isSubmitting && <Loading />}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default NewTemplate;

const Wrapper = ({ children }) => {
    return (
        <Box sx={{ minHeight: "100vh" }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: "100vh",
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: {
                                xs: "calc(100vh - 134px)",
                                md: "calc(100vh - 112px)",
                            },
                        }}
                    >
                        <Grid item>
                            <MainCard
                                sx={{
                                    maxWidth: { xs: 400, lg: 475 },
                                    margin: { xs: 2.5, md: 3 },
                                    "& > *": {
                                        flexGrow: 1,
                                        flexBasis: "50%",
                                    },
                                }}
                                content={false}
                                border={false}
                                boxShadow
                                shadow={(theme) => theme.customShadows.z1}
                            >
                                <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
                                    {children}
                                </Box>
                            </MainCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
