const networks = {
  "024": "MTN",
  "055": "MTN",
  "057": "AirtelTigo",
  "056": "AirtelTigo",
  "020": "Vodafone",
  "050": "Vodafone",
  "023": "GLO",
  "054": "MTN",
  "026": "AirtelTigo",
  "027": "AirtelTigo",
  "025": "MTN",
  "059": "MTN",
};

const getContactErrs = (contacts, rows) => {
  const errs = [];
  const contactsListFinal = [];

  for (let el in contacts.split(",")) {
    const number = contacts.split(",")[el];

    if (number.replace("233", "0").length !== 10) {
      errs.push({
        errID: 0,
        number,
        reason: `Number is ${
          number.length < 10 ? "less" : "more"
        } than 10 digits`,
      });
    }

    if (rows.includes(number.replace("233", "0"))) {
      errs.push({ errID: 1, number, reason: `Number already exists in group` });
    }

    if (
      !Object.keys(networks).includes(
        `${number.replace("233", "0").slice(0, 3)}`
      )
    ) {
      // if (!non_international_prefixes.includes(`0${number.replace('233', '0').slice(3, 5)}`)) {
      errs.push({ errID: 2, number, reason: "Unknown network" });
    }

    if (contactsListFinal.includes(number.replace("233", "0"))) {
      errs.push({ errID: 3, number, reason: "Duplicate found" });
    } else {
      contactsListFinal.push(number.replace("233", "0"));
    }
  }

  return { errs, contactsListFinal };
};

const formatContacts = (event) => {
  let value = event.target.value;

  value = value.replace("\r\n", " ");
  value = value.replace("\n\r", " ");
  value = value.replace("\r", " ");
  value = value.replace(/[" "]/g, ",");
  value = value.replace(", ", ",");
  value = value.replace(/[\n\r]/g, ",");

  return value;
};

export { getContactErrs, networks, formatContacts };
