// assets
import { UserOutlined } from '@ant-design/icons';

// icons


// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'data',
    title: 'Data',
    type: 'group',
    children: [
        {
            id: 'contacts',
            title: 'Contacts',
            type: 'item',
            url: '/contacts',
            icon: UserOutlined,
        },
    ]
};

export default pages;
