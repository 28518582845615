import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import ContactGroups from "pages/contacts";
import Contacts from "pages/contacts/contacts";
import Messages from "pages/messages";
import NewMessage from "pages/messages/new";
import SenderIDs from "pages/sender-ids/index";
import Templates from "pages/templates/index";
import NewTemplate from "pages/templates/new";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// // render - sample page
// const SamplePage = Loadable(lazy(() => import("pages/extra-pages/SamplePage")));

// // render - utilities
// const Typography = Loadable(
//   lazy(() => import("pages/components-overview/Typography"))
// );
// const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
// const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
// const AntIcons = Loadable(
//   lazy(() => import("pages/components-overview/AntIcons"))
// );

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/",
      children: [
        {
          path: "contacts",
          element: <ContactGroups />,
        },
      ],
    },

    {
      path: "contacts",
      children: [
        {
          path: ":groupID",
          element: <Contacts />,
        },
      ],
    },

    {
      path: "messages",
      element: <Messages />,
    },
    {
      path: "messages",
      children: [
        {
          path: "new",
          element: <NewMessage />,
        },
      ],
    },
    {
      path: "templates",
      element: <Templates />,
    },
    {
      path: "templates",
      children: [
        {
          path: "new",
          element: <NewTemplate />,
        },
      ],
    },
    {
      path: "sender-ids",
      element: <SenderIDs />,
    },
  ],
};

export default MainRoutes;
