import { useAtomValue } from "jotai";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import TableHead from "../../components/TableHead";
// material-ui
import {
    Box,
    Button,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";

// third-party
import NumberFormat from "react-number-format";

// project import
import Dot from "components/@extended/Dot";
import { userAtom } from "jotai-state/atoms";
import { getSentSMS } from "lib/firebase";

function createData(messageID, senderID, totalContacts, status, totalAmount) {
    return { messageID, senderID, totalContacts, status, totalAmount };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: "messageID",
        align: "left",
        disablePadding: false,
        label: "Message ID",
    },
    {
        id: "senderID",
        align: "left",
        disablePadding: true,
        label: "Sender ID",
    },
    {
        id: "totalContacts",
        align: "right",
        disablePadding: false,
        label: "Total Contacts",
    },
    {
        id: "status",
        align: "left",
        disablePadding: false,

        label: "Status",
    },
    {
        id: "totalAmount",
        align: "right",
        disablePadding: false,
        label: "Total Amount",
    },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
    let color;

    switch (status) {
        case "Pending":
            color = "warning";
            break;
        case "Sent":
            color = "success";
            break;
        case "Failed":
            color = "error";
            break;
        default:
            color = "primary";
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{status}</Typography>
        </Stack>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function OrderTable({ data }) {
    const [order] = useState("asc");
    const [orderBy] = useState("messageID");
    const [selected] = useState([]);

    const [rows, setRows] = useState([]);

    const isSelected = (messageID) => selected.indexOf(messageID) !== -1;
    const location = useLocation();
    const userData = useAtomValue(userAtom);
    const navigate = useNavigate();

    useEffect(() => {
        const _getSMS = async () => {
            const sms = await getSentSMS(userData.token);

            setRows([...sms]);
        };

        _getSMS();
    }, []);

    return (
        <Box>
            {/* <Link  to="/new"> */}
            <Button
                onClick={() => navigate("/messages/new")}
                color="success"
                variant="outlined"
            >
                Send New SMS
            </Button>
            {/* </Link> */}
            <TableContainer
                sx={{
                    width: "100%",
                    overflowX: "auto",
                    position: "relative",
                    display: "block",
                    maxWidth: "100%",
                    "& td, & th": { whiteSpace: "nowrap" },
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        "& .MuiTableCell-root:first-child": {
                            pl: 2,
                        },
                        "& .MuiTableCell-root:last-child": {
                            pr: 3,
                        },
                    }}
                >
                    <TableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row.messageID);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.messageID}
                                    selected={isItemSelected}
                                >
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        align="left"
                                    >
                                        <Link
                                            color="secondary"
                                            component={RouterLink}
                                            to=""
                                        >
                                            {row.messageID}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.senderID}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.sent_contacts.length +
                                            row.unsent_contacts.length}
                                    </TableCell>
                                    <TableCell align="left">
                                        <OrderStatus status={row.status} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberFormat
                                            value={row.totalAmount}
                                            displayType="text"
                                            thousandSeparator
                                            prefix=""
                                            suffix=" Units"
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
