import { Link as RouterLink } from "react-router-dom";

import { OutlinedInput } from "@mui/material/index";

import { Button } from "@mui/material/index";

// material-ui
import {
    Box,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";

// third-party
import OrderTableHead from "components/TableHead";
import Loading from "components/loading";
import { useAtomValue } from "jotai";
import { userAtom } from "jotai-state/atoms";
import { getSenderIDs, saveSenderID } from "lib/firebase";
import { useEffect, useState } from "react";

const SenderIDs = () => {
    const headCells = [
        {
            id: "senderID",
            align: "left",
            disablePadding: false,
            label: "Sender ID",
        },

        {
            id: "status",
            align: "left",
            disablePadding: false,

            label: "Status",
        },
    ];

    const [id, setID] = useState("");

    const [ids, setIDs] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const userData = useAtomValue(userAtom);

    const _getSenderIDs = async () => {
        const ids = await getSenderIDs(userData.token);

        setIDs(ids);
    };

    useEffect(() => {
        _getSenderIDs();
    }, []);

    const idHasErr = (id) => /^[a-zA-Z0-9]+$/.test(id);

    const addID = async () => {
        if (id) {
            if (idHasErr) {
                setIsLoading(true);

                await saveSenderID(id, userData.token);
                fetch(
                    "https://gateway.api.dawurobo.com/v1/dawurobo_admin/v3/admin/notify",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "Application/Json",
                        },
                        body: JSON.stringify({
                            messageTitle: "Pending Sender ID!",
                            messageBody: id,
                        }),
                    }
                );

                _getSenderIDs();
                setIsLoading(false);
            } else {
            }
        }
    };

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <OutlinedInput
                    id="senderID"
                    onChange={(e) => setID(e.target.value)}
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 11 }}
                    name="senderID"
                    error={id && !idHasErr(id)}
                    placeholder="sender ID must be less than 11 characters "
                    fullWidth
                />
                <Button
                    disableElevation
                    fullWidth
                    size="large"
                    disabled={!id || !idHasErr(id)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 100 }}
                    onClick={addID}
                >
                    Add
                </Button>
            </Box>
            {isLoading && <Loading />}
            <TableContainer
                sx={{
                    width: "100%",
                    overflowX: "auto",
                    position: "relative",
                    display: "block",
                    maxWidth: "100%",
                    "& td, & th": { whiteSpace: "nowrap" },
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        "& .MuiTableCell-root:first-child": {
                            pl: 2,
                        },
                        "& .MuiTableCell-root:last-child": {
                            pr: 3,
                        },
                    }}
                >
                    <OrderTableHead
                        headCells={headCells}
                        // order={order}
                        // orderBy={orderBy}
                    />
                    <TableBody>
                        {ids.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    //   aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    //   selected={isItemSelected}
                                >
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        align="left"
                                    >
                                        <Link
                                            color="secondary"
                                            component={RouterLink}
                                            to=""
                                        >
                                            {row.id}
                                        </Link>
                                    </TableCell>

                                    <TableCell align="left">
                                        {row.status}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SenderIDs;
