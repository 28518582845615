import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import Drawer from "./Drawer";
import Header from "./Header";

// types
import { openDrawer } from "store/reducers/menu";

import { useAtomValue } from "jotai";
import { userAtom } from "jotai-state/atoms";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const userData = useAtomValue(userAtom);

    const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    const [showFeed, setShowFeed] = useState(false);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        const _showFeed = userData.token !== undefined;

        if (_showFeed) {
            setShowFeed(true);
        } else {
            navigate("/login");
        }
    }, []);

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (
        showFeed && (
            <Box sx={{ display: "flex", width: "100%" }}>
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                <Box
                    component="main"
                    sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
                >
                    <Toolbar />
                    {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
                    <Outlet />
                </Box>
            </Box>
        )
    );
};

export default MainLayout;
