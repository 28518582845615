import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import AuthJWT from "pages/authentication/auth-forms/AuthJWT";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthRegister = Loadable(
    lazy(() => import("pages/authentication/Register"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "login",
            element: <AuthLogin />,
        },
        {
            path: "register",
            element: <AuthRegister />,
        },
        {
            path: "token",
            element: <AuthJWT />,
        },
    ],
};

export default LoginRoutes;
